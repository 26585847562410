import React, {useState, useEffect, useRef} from 'react';

import s from './Pin.module.scss';

const PinComponent = ({
  pin,
  value,
  minValue,
  maxValue,
  step,
  setValue,
  minQuantity,
  sliderItemActive,
  setSliderItemActive,
  style,
}) => {
  const [pinWidth, setPinWidth] = useState(0);
  const [pinStep, sePinStep] = useState(minValue);
  const [sliderValue, setSliderValue] = useState(minValue);
  const [left, setLeft] = useState(minValue);

  const mainBlockRef = useRef(null);
  const pinContainerRef = useRef(null);

  useEffect(() => {
    if (mainBlockRef.current && pinContainerRef.current) {
      const mainBlockWidth = mainBlockRef.current.getBoundingClientRect().width;
      const pinContainerWidth =
        pinContainerRef.current.getBoundingClientRect().width;

      const actualMainBlockWidth = Math.ceil(mainBlockWidth);
      const actualPinContainerWidth = Math.ceil(pinContainerWidth);

      if (actualMainBlockWidth !== 0) {
        const pinStep = actualMainBlockWidth / maxValue;
        sePinStep(pinStep);
        setPinWidth(actualPinContainerWidth);
      }
    }
  }, [maxValue, step]);

  useEffect(() => {
    if ((value <= minValue || value < minQuantity) && !sliderItemActive[1]) {
      setSliderItemActive({...sliderItemActive, 1: true, 2: false, 3: false});
    }

    if (value >= minQuantity && value < maxValue && !sliderItemActive[2]) {
      setSliderItemActive({...sliderItemActive, 1: false, 2: true, 3: false});
    }

    if (value >= maxValue && !sliderItemActive[3]) {
      setSliderItemActive({...sliderItemActive, 1: false, 2: false, 3: true});
    }
  }, [
    value,
    sliderItemActive,
    minValue,
    maxValue,
    minQuantity,
    setSliderItemActive,
  ]);

  useEffect(() => {
    if (value <= minValue) {
      setLeft(-pinWidth / 2 + 2);
      setSliderValue(minValue);
      setValue(minValue);
    }

    if (value > minValue && value < maxValue) {
      setSliderValue(value);
      setLeft(() => value * pinStep - pinWidth / 2);
    }

    if (value >= maxValue) {
      setLeft(pinStep * maxValue - pinWidth / 2 - 2);
      setSliderValue(maxValue);
    }
  }, [value, minValue, maxValue, pinStep, pinWidth, setValue]);

  const setValueHandler = (newValue) => {
    if (newValue !== value && newValue > minValue) {
      setLeft(() => {
        if (value < newValue || value > newValue)
          return newValue * pinStep - pinWidth / 2;
      });
      setValue(newValue * step);

      if (newValue >= minQuantity && newValue < maxValue) {
        setSliderItemActive({...sliderItemActive, 1: false, 2: true, 3: false});
      }
    }

    if (newValue === `${minValue}`) {
      setLeft(-pinWidth / 2 + 2);
      setValue(minValue * step);
      setSliderItemActive({...sliderItemActive, 1: true, 2: false, 3: false});
    }

    if (newValue === `${maxValue}`) {
      setSliderItemActive({...sliderItemActive, 1: false, 2: false, 3: true});
    }

    if (step > 1 && Number(newValue) + step > maxValue) {
      setLeft(pinStep * maxValue - pinWidth / 2 - 2);
      setValue(maxValue * step);
    }
  };

  return (
    <>
      <div ref={mainBlockRef} className={s.container}>
        <div
          ref={pinContainerRef}
          className={`${s.pinContainer} 'background'`}
          style={{left: `${left}px`}}>
          {pin}
        </div>
        <input
          className={s.inputRange}
          style={style ? style : {}}
          type="range"
          value={sliderValue}
          min={minValue}
          max={maxValue}
          step="1"
          onChange={(e) => setValueHandler(e.target.value)}
        />
      </div>
    </>
  );
};

export default PinComponent;
