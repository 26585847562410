let BASE_URL = '';

if (/localhost/g.test(window.location.href))
  BASE_URL = process.env.REACT_APP_BASE_URL_DEV;
if (/netlify/g.test(window.location.href))
  BASE_URL = process.env.REACT_APP_BASE_URL_STAGE;
if (/ekip-auto/g.test(window.location.href))
  BASE_URL = process.env.REACT_APP_BASE_URL_PROD;

export default BASE_URL;
